// extracted by mini-css-extract-plugin
export var productDetailsAccordion = "style-mod--product-details-accordion--1UVeT";
export var productImage = "style-mod--product-image--2tvOV";
export var content = "style-mod--content--2TYSl";
export var category = "style-mod--category--QOt7C";
export var pdTitle = "style-mod--pd-title--2gvdV";
export var accordionCard = "style-mod--accordion-card--3915L";
export var cardHeader = "style-mod--card-header--s0Uy5";
export var accordionIcon = "style-mod--accordion-icon--2KgjO";
export var accordionIconOpen = "style-mod--accordion-icon-open--H-NQa";
export var accordionTitle = "style-mod--accordion-title--3y-1L";
export var accordionSubtitle = "style-mod--accordion-subtitle--2_fQA";
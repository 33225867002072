import React, { useState } from "react";
import {
  Row, Col, Accordion, Card
} from "react-bootstrap";
import {
  productDetailsAccordion, content,
  category, accordionCard, cardHeader,
  pdTitle, accordionTitle, accordionSubtitle,accordionIconOpen,
  productImage, accordionIcon, accordionBody
} from "./style.mod.scss";
import RichText from "components/rich-text";
import {  ChevronDown } from "react-bootstrap-icons";
import getImagePath from "helpers/get-image-path";

const ProductDetailsAccordion = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { title , subtitle, timeline, image } = data ;

  return (
    <div className={productDetailsAccordion}>
      <Row>
        <Col sm="12" lg="6" style={{ padding:"unset" }}>
          <div className={productImage} style={{ backgroundImage: `url(${getImagePath(image, true)})` }}>
          </div>
        </Col>
        <Col  sm="12" lg="6">
          <div className={content}>
            <h2 className={category}>{subtitle}</h2>
            <h1 className={pdTitle}>{title}</h1>
            
            <Accordion>
              {
                timeline?.map((_tab, index) => (
                  <Card key={index} className={accordionCard}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={_tab._key}
                      className={cardHeader}
                      onClick={() => setOpen(open === _tab._key ? null : _tab._key)}
                    >
                      <p className={accordionTitle}>{_tab.timelineTitle}</p>
                      <p className={accordionSubtitle}>{_tab.timelineSubtitle}</p>
                      <span className={`${accordionIcon} ${_tab._key !== open ? "" : accordionIconOpen}`}>
                        <ChevronDown/>
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={_tab._key}>
                      <Card.Body className={accordionBody}>
                        <RichText blocks={_tab.timelineText} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))
              }
            </Accordion>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetailsAccordion;
